import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Subscription } from 'rxjs';
import { defaultDarkTheme, defaultLightTheme } from '@app/modules/client/default-ui-setting-values';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  scheme = 'dark';
  subscription: Subscription;

  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void { 
    // this.layoutService.setUIWithSettingValue(this.layoutService.getDeafultUISettings());
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  changeScheme() {
    // this.layoutService.applyTheme(
    //   this.scheme == 'dark' ? defaultLightTheme.key : defaultDarkTheme.key,
    //   this.scheme == 'dark' ? defaultLightTheme.mode : defaultDarkTheme.mode,
    //   this.scheme == 'dark' ? defaultLightTheme.pace : defaultDarkTheme.pace
    // );
  }
}
