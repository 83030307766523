<div class="layout-topbar justify-content-between">
    <div class="flex align-items-center">

        <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
            <span></span>
        </button>

        <div class="grid mx-1 mt-3" *ngIf="isCustomerPanel && customerList.length ">
            <span class="p-float-label customer-list ms-3">
                <p-dropdown id="dropdown1" class="w-full" [options]="customerList" [autoDisplayFirst]="true"
                    (onChange)="store.setSelectedCustomer($event.value)" optionLabel="companyName"
                    [filter]="true"></p-dropdown>
                <label for="dropdown1">Customer</label>
            </span>
        </div>

        <ul class="topbar-menu">
            <li *ngFor="let item of tabs; let i = index">
                <a [routerLink]="item.routerLink" routerLinkActive="active-route"
                    [routerLinkActiveOptions]="item.routerLinkActiveOptions||{paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'}"
                    [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling"
                    [preserveFragment]="item.preserveFragment!" [skipLocationChange]="item.skipLocationChange!"
                    [replaceUrl]="item.replaceUrl!" [state]="item.state" [queryParams]="item.queryParams">
                    <span>{{item.label}}</span>
                </a>
                <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
            </li>
        </ul>
    </div>


    <a class="dashboard-icon" [routerLink]="['/service/dashboard']">
        <div class="" style="padding: 10px;">
            <img [src]="logoImg" [hidden]="logoImg == ''" height="50" />
        </div>
    </a>

    <!-- <div class="topbar-search" [ngClass]="{'topbar-search-active': searchActive}">
        <button class="topbar-searchbutton p-link" type="button" (click)="activateSearch()">
            <i class="pi pi-search"></i>
        </button>

        <div class="search-input-wrapper">
            <span class="p-input-icon-right">
                <input #searchinput type="text" pInputText placeholder="Search" (blur)="deactivateSearch()" (keydown.escape)="deactivateSearch()"/>
                <i class="pi pi-search"></i>
            </span>
        </div>
    </div> -->
    <div class="flex align-items-center">
        <ng-container *ngIf="!isSuperAdmin">
            <button pButton *ngFor="let page of quickPages" [icon]="page.icon" [routerLink]="[page.link]"
                class="p-button-rounded p-button-outlined p-button-primary ml-1 mr-1 layout-topbar-desktop flex justify-content-center align-items-center  "
                #quickButtons [title]="page.title" [ngClass]="{'active-btn': page.link === currentRoute}"
                [ngStyle]="{'backgroundColor': setActiveQuickButton(quickButtons)}"></button>
            <span class="p-overlay-badge">
                <button pButton icon="pi pi-phone"
                    class="p-button-rounded p-button-success ml-2 mr-1 layout-topbar-desktop softphone-button"
                    title="Phone" (click)="layoutService.toggleSoftPhone()"></button>
                <span class="p-badge p-badge-danger" style="top: 6px; right: 12px;"
                    *ngIf="layoutService.getMissedCalls()>0"></span>
            </span>
        </ng-container>

        <div class="topbar-profile">
            <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterClass="hidden"
                enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout">
                <img alt="avatar" src="assets/images/avatar.png">
                <span class="profile-details">
                    <span class="profile-name">{{userInfo.name}}</span>
                    <span class="profile-job">{{userInfo.email}}</span>
                </span>
                <i class="pi pi-angle-down"></i>
            </button>

            <ul #profileMenu
                class="list-none p-0 py-2 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top sm:w-17rem mt-2 right-0 top-auto">
                <li>
                    <div routerLink='/service/user/account' (click)="closeMenu()"
                        class="p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                        <div class="sub_info">
                            <i class="pi pi-verified ml-3 mr-3"></i><span class="title">{{userInfo.accountName}}</span> <br> <span class="title_id">(Id: <b>{{userInfo.accountID}}</b>)</span>
                            <br>
                            <div *ngIf="!isSuperAdmin" class="flex align-items-center pt-4 pb-2">
                                <i class="pi pi-money-bill ml-3 mr-3"></i>
                                <div>
                                    <span class="label">Balance:&nbsp;</span><span class="value"><b>${{!isAdmin ? balance?.toFixed(2) : '0.00'}}</b></span>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                </li>
                <li class="listing" (click)="closeMenu()">
                    <a pRipple
                        class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                        [routerLink]="['/service/user/edit/', this.userId]">
                        <i class="pi pi-user-edit ml-3 mr-3"></i>
                        <span>User Profile</span>
                    </a>
                    <a pRipple *ngIf="isSuperAdmin ? false : true"
                        class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                        [routerLink]="['/service/billing/settings']">
                        <i class="pi pi-wallet ml-3 mr-3"></i>
                        <span>Billing Settings</span>
                    </a>
                    <a pRipple (click)="toggleMode();"
                        class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                        <i class="pi pi-send ml-3 mr-3"></i>
                        <span>{{colorScheme=='dark' ? 'Light' : 'Dark'}} Mode</span>
                    </a>
                    <a pRipple (click)="onSignout();"
                        class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                        <i class="pi pi-power-off ml-3 mr-3"></i>
                        <span>Sign Out</span>
                    </a>
                    <a pRipple *ngIf="impersonateToken" (click)="deimpersonate()"
                        class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                        <i class="pi pi-sign-out ml-3 mr-3"></i>
                        <span>Deimpersonate</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>