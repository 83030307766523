export const environment = {
  production: true,
  api: {
    core: {
      uri: 'https://800-nyc2.call-metrics.com:9443',
      path: '/api/v1',
    },
  },
  softphone: {
    scheme: 'wss',
    server: '800-nyc2.call-metrics.com',
    port: '6061',
    path: '/ws',
    sipDomain: 'tenant_',
  },
  stripe: {
    key: 'pk_live_51ODUohGqe6lwJuXemHnVPmzUqyTkW8TB5zmSkf8s9J0cFWgUuUpXa1gxKnRkcEsXfUdKedTyM7NLuha2phqtzkAz00PPwUOr1R',
  },
  recaptcha: {
    key: '6LdAYI4pAAAAAFQQLBVJzDlOWWZgLsqpu5UrLitX'
  }
};
